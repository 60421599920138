<template>
	<div id="supplement">
		<div class="nav">
			<top-vue></top-vue>
		</div>
		<div class="su_contant" style="width:1200px;margin:35px auto 15px;">
			<div
				style="height: 50px;margin:0;padding: 10px 6px 0px 0px;background: #9eacfd;border-radius: 5px;text-align: left;">
				<div class="el-form-item mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration" style="color: #fff;font-size: 22px;font-weight: bold;">基本信息：</span>
					<!-- <el-select v-model="form.ifResultRight" placeholder="较准确">
						<el-option v-for="item in pj" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select> -->
				</div>
			</div>
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">姓名：</span>
					<el-input v-model="form.studentName" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">市县：</span>
					<el-input v-model="form.fromCity" placeholder="请输入市县"></el-input>
				</el-form-item>
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">科目：</span>
					<!-- <el-select v-model="form.syS_CourseGroup" placeholder="">
						<el-option v-for="item in wl" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select> -->
					<AuthSelect size="medium" :isEdit="true" :data-source="'SYS_CourseGroup'" v-model="form.syS_CourseGroup"
						style="display: inline-block;">
					</AuthSelect>
				</el-form-item>
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">性别：</span>
					<el-select v-model="form.syS_Sex" placeholder="">
						<el-option v-for="item in sexs" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz sg" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">身高：</span>
					<el-input v-model="form.height" placeholder="">
						<template #suffix>
							CM
						</template>
					</el-input>
				</el-form-item>
				<el-form-item class="mz tz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">体重：</span>
					<el-input v-model="form.weight" placeholder="">
						<template #suffix>
							KG
						</template>
					</el-input>
				</el-form-item>
			</el-form>
			<div
				style="height: 50px;margin:0;padding: 10px 6px 0px 0px;background:#9eacfd;text-align: left;border-radius: 5px;">
				<span class="demonstration"
					style="color: #fff;font-size: 22px;font-weight: bold;padding-left: 28px;line-height: 2;">学科强弱与总分评估：</span>
			</div>
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">语文：</span>
					<el-select v-model="form.scoreChinese" placeholder="">
						<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">数学：</span>
					<el-select v-model="form.scoreMath" placeholder="">
						<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">外语：</span>
					<el-select v-model="form.scoreForeignLanguage" placeholder="">
						<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">物理：</span>
					<el-select v-model="form.scorePhysics" placeholder="">
						<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">历史：</span>
					<el-select v-model="form.scoreHistory" placeholder="">
						<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">化学：</span>
					<el-select v-model="form.scoreChemistry" placeholder="">
						<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration ">生物：</span>
					<el-select v-model="form.scoreBiology" placeholder="">
						<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">地理：</span>
					<el-select v-model="form.scoreGeography" placeholder="">
						<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">政治：</span>
					<el-select v-model="form.scorePolitics" placeholder="">
						<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">总分：</span>
					<el-select v-model="form.scoreLevel" placeholder="">
						<el-option v-for="item in allgrade" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz qt" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">物理电学：</span>
					<el-select v-model="form.electrics" placeholder="">
						<el-option v-for="item in good" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz qt" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration ">物理力学：</span>
					<el-select v-model="form.dynamics" placeholder="">
						<el-option v-for="item in good" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="mz qt" style="margin-left: 15px;margin-right: 25px;margin: 0 25px 14px 15px">
					<span class="demonstration">外语听说：</span>
					<el-select v-model="form.fLListening" placeholder="">
						<el-option v-for="item in listens" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div
				style="height: 50px;margin:0;padding: 10px 6px 0px 0px;background:#9eacfd;text-align: left;border-radius: 5px;">
				<span class="demonstration"
					style="color: #fff;font-size: 22px;font-weight: bold;padding-left: 28px;line-height: 2;">其它信息：</span>
			</div>
			<el-form ref="form" :model="form" label-width="80px" class="fx">
				<el-form-item class="tc">
					<span class="demonstration">特长亮点：</span>
					<el-input v-model="form.speciality">
					</el-input>
				</el-form-item>
				<el-form-item class="tc">
					<span class="demonstration">体检结果：</span>
					<el-input v-model="form.physicalExamResult">
					</el-input>
				</el-form-item>
				<el-form-item class="tc">
					<span class="demonstration">就业区域：</span>
					<el-input v-model="form.jobCity"></el-input>
				</el-form-item>
				<el-form-item class="tc">
					<span class="demonstration">意向就业方向：</span>
					<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" v-model="form.jobIdea">
					</el-input>
				</el-form-item>
				<el-form-item class="tc">
					<span class="demonstration">人脉方向：</span>
					<el-input v-model="form.humanRelationship">
					</el-input>
				</el-form-item>
				<el-form-item class="tc">
					<span class="demonstration">职业价值观：</span>
					<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" v-model="form.workValues">
					</el-input>
					<!-- <div
						style="width: 85%;border: 2px #4e6ffc solid;border-radius: 5px;padding: 10px 20px;text-align: left;">
						<el-checkbox-group v-model="form.workValues">
							<el-checkbox label="工资高 福利好"></el-checkbox>
							<el-checkbox label="工作环境(物质方面)舒适"></el-checkbox>
							<el-checkbox label="人际关系良好"></el-checkbox>
							<el-checkbox label="工作稳定 有保障"></el-checkbox>
							<el-checkbox label="能提供较好的受教育机会"></el-checkbox>
							<el-checkbox label="有较高的社会地位"></el-checkbox>
							<el-checkbox label="工作不紧张 外部压力少"></el-checkbox>
							<el-checkbox label="能充分发挥自己的能力"></el-checkbox>
						</el-checkbox-group>
					</div> -->
				</el-form-item>
			</el-form>
		</div>
		<div style="width: 100px;height: 100px;margin: 0 auto;">
			<el-button class="button" @click="nextClick"
				style="border: none;padding: 15px 73px;color: #fff;font-size: 20px;font-weight: bold; border-radius: 25px;">
				下一步</el-button>
		</div>
	</div>
</template>

<script>
import {
	mapState,
} from "vuex"
import topVue from '@/components/top.vue';
import * as systemApi from "@/api/subjectSystemApi"
import AuthSelect from '@/components/AuthSelect.vue'

export default {
	components: {
		AuthSelect,
		topVue,
	},
	data() {
		return {
			//test:[],
			form: {
				scoreLevel: '', //分数水平
				strongSubjects: '', //优势学科
				rejectSubjects: '', // 排斥学科
				paintinBasis: '', // 绘画基础
				businessPlan: '', // 创业打算
				postgradPlan: '', // 考研打算
				undergradEmploy: '', // 本科就业
				ifResultRight: '', //准确度
				speciality: '如擅长辩论、写作、组织领导、经商、交际、主持、对数字敏感、擅长理财、有绘画基础等等', //特长亮点
				physicalExamResult: '一切正常', //体检结果
				jobCity: '区外、南宁、柳州、桂林等', //就业区域
				humanRelationship: '无', //人脉方向
				//就业方向
				jobIdea: '请把无意向的方向去掉，把有意向的方向留下来：国家党政机关（局委办）、国际组织（外交）、中小学、学前教育、大中专院校、西医医院、中医医院、预防医学、医药、律师事务所、会计师事务所、银行、新闻媒体、经商、国贸营销、管理、外语、心理学、计算机软件开发、自动化（人工智能）、电气（电网）、电子信息、通信、航空航天、铁道、轨道交通、民航、测绘、仪器、兵器、材料、化工制药、环境保护、机械设计、机械制造、 能源动力、建筑、土木、水利、海洋工程、核工程（核电）、农业、林业、海洋航海、地质、矿业、石油、食品科学、生物科技、天文、气象、纺织服装',
				studentName: '', //姓名
				fromCity: '', //市县
				scoreChinese: '', //语文
				syS_Sex: '', //性别
				height: '', //身高
				weight: '', //体重
				syS_CourseGroup: '', //文理科
				scoreMath: '', //数学
				scoreForeignLanguage: '', //英语
				scorePhysics: '', //物理
				scoreHistory: '', //历史
				scoreChemistry: '', //化学
				scoreBiology: '', //生物
				scoreGeography: '', //地理
				scorePolitics: '', //思想政治
				electrics: '', //物理电学
				dynamics: '', //物理力学
				fLListening: '', //外语听说
				workValues: '工资高、福利好、工作环境(物质方面)舒适，人际关系良好、工作稳定、有保障，能提供较好的受教育机会，有较高的社会地位，工作不太紧张，外部压力少，能充分发挥自己的能力特长', //职业价值观
			},

			pj: [{
				value: '较准确',
				label: '较准确'
			}, {
				value: '有点准',
				label: '有点准'
			}, {
				value: '不准确',
				label: '不准确'
			}],
			wl: [{
				value: '2',
				label: '理工科'
			}, {
				value: '1',
				label: '文史科'
			}],
			sexs: [{
				value: '1',
				label: '男'
			}, {
				value: '0',
				label: '女'
			}],
			grade: [{
				value: '强',
				label: '强'
			}, {
				value: '中上',
				label: '中上'
			}, {
				value: '中等',
				label: '中等'
			}, {
				value: '弱但不排斥',
				label: '弱但不排斥'
			}, {
				value: '弱且排斥',
				label: '弱且排斥'
			},],
			physics: [{
				value: '100-110',
				label: '100-110'
			}, {
				value: '90-100',
				label: '90-100'
			}, {
				value: '80-90',
				label: '80-90'
			},
			{
				value: '70-80',
				label: '70-80'
			}, {
				value: '60-70',
				label: '60-70'
			}, {
				value: '50-60',
				label: '50-60'
			}, {
				value: '40-50',
				label: '40-50'
			}, {
				value: '40以下',
				label: '40以下'
			}
			],
			grades: [{
				value: '90-100',
				label: '90-100'
			}, {
				value: '80-90',
				label: '80-90'
			},
			{
				value: '70-80',
				label: '70-80'
			}, {
				value: '60-70',
				label: '60-70'
			}, {
				value: '50-60',
				label: '50-60'
			}, {
				value: '40-50',
				label: '40-50'
			}, {
				value: '40以下',
				label: '40以下'
			}
			],
			good: [{
				value: '学得好',
				label: '学得好'
			}, {
				value: '学得不好',
				label: '学得不好'
			},
			{
				value: '文科生不用学',
				label: '文科生不用学'
			}
			],
			listens: [{
				value: '很棒',
				label: '很棒'
			}, {
				value: '中上',
				label: '中上'
			},
			{
				value: '一般',
				label: '一般'
			},
			{
				value: '很差',
				label: '很差'
			}
			],
			allgrade: [{
				value: '特控线上130分以上',
				label: '特控线上130分以上'
			}, {
				value: '特控线上120分以上',
				label: '特控线上120分以上'
			},
			{
				value: '特控线上110分以上',
				label: '特控线上110分以上'
			},
			{
				value: '特控线上100分以上',
				label: '特控线上100分以上'
			}, {
				value: '特控线上90分以上',
				label: '特控线上90分以上'
			}, {
				value: '特控线上80分以上',
				label: '特控线上80分以上'
			}, {
				value: '特控线上70分以上',
				label: '特控线上70分以上'
			}, {
				value: '特控线上60分以上',
				label: '特控线上60分以上'
			}, {
				value: '特控线上50分以上',
				label: '特控线上50分以上'
			}, {
				value: '特控线上40分以上',
				label: '特控线上40分以上'
			}, {
				value: '特控线上30分以上',
				label: '特控线上30分以上'
			}, {
				value: '特控线上20分以上',
				label: '特控线上20分以上'
			}, {
				value: '特控线上10分以上',
				label: '特控线上10分以上'
			}, {
				value: '特控线附近',
				label: '特控线附近'
			}, {
				value: '特控线下20分以内',
				label: '特控线下20分以内'
			}, {
				value: '特控线下30分以内',
				label: '特控线下30分以内'
			}, {
				value: '特控线下50分以内',
				label: '特控线下50分以内'
			}, {
				value: '本科线上30分以内',
				label: '本科线上30分以内'
			}, {
				value: '本科线上20分以内',
				label: '本科线上20分以内'
			}, {
				value: '本科线附近',
				label: '本科线附近'
			}
			],

		}
	},
	created() {
		this.getStatus()
	},
	computed: {
		...mapState(["majorStatus", "subjectData"]),
	},
	methods: {
		getStatus() {
			console.log('this.majorStatus=' + this.majorStatus);
			if (this.majorStatus == '') {
				let status = sessionStorage.getItem('majorStatus');
				this.$store.commit('setMajorStatus', status);
			}
			if (Object.keys(this.subjectData).length == 0) {
				let subjectData = JSON.parse(sessionStorage.getItem('subjectData'));
				console.log(subjectData);
				this.$store.commit('setSubjectData', subjectData);
			}
			if (this.majorStatus == '0') {
				this.$router.push('introduce');
			} else if (this.majorStatus == '1' || this.majorStatus == '2' || this.majorStatus == '3') {
				this.$router.push('mojor');
			} else if (this.majorStatus == '5') {
				this.$router.push('preliminary');
			} else if (this.majorStatus == '7') {
				this.$router.push('final');
			}
			this.getReport()
		},
		getReport() {
			let query = {
				studentName: this.subjectData.studentName,
				mobileTel: this.subjectData.mobileTel
			}
			systemApi.getMemberInfo(query).then(res => {
				console.log(res);
				if (res.result.speciality == '' || !res.result.speciality) {
					res.result.speciality = this.form.speciality;
				}
				if (res.result.physicalExamResult == '' || !res.result.physicalExamResult) {
					res.result.physicalExamResult = this.form.physicalExamResult;
				}
				if (res.result.jobCity == '' || !res.result.jobCity) {
					res.result.jobCity = this.form.jobCity;
				}
				if (res.result.jobIdea == '' || !res.result.jobIdea) {
					res.result.jobIdea = this.form.jobIdea;
				}
				if (res.result.humanRelationship == '' || !res.result.humanRelationship) {
					res.result.humanRelationship = this.form.humanRelationship;
				}
				if (res.result.workValues == '' || !res.result.workValues) {
					res.result.workValues = this.form.workValues;
				}

				this.form = res.result;

				// if (this.form.workValues == '' || !this.form.workValues) {
				// 	this.form.workValues = [];
				// } else {
				// 	this.form.workValues = this.form.workValues.split(',');
				// }
			})
		},
		nextClick() {
			//this.form.workValues = this.form.workValues.join(',');
			this.form.subjectTestId = this.subjectData.id;
			systemApi.saveMemberInfo(this.form).then(res => {
				console.log(res);
				if (res.code == 200) {
					// this.$message({
					// 	type:'success',
					// 	message:res.message
					// })
					this.$store.commit('setMajorStatus', '7');
					this.$router.push("/final");
				} else {
					this.$message({
						type: 'error',
						message: res.message
					})
				}

			}).catch(() => {
				this.$message({
					type: 'error',
					message: '网络错误，请重试'
				})
			})

		}
	}
}
</script>

<style>
#supplement {
	.el-input__inner {
		font-weight: bold;
		color: #000;
	}

	/* .fx .el-input {
	width: 85% !important;
	margin-bottom: 30px;
} */

	.el-form-item.mz {
		margin: 0 70px 14px 30px !important;
	}

	span.demonstration {
		font-size: 20px;
	}

	.sg .el-input__suffix-inner,
	.tz .el-input__suffix-inner {
		line-height: 2;
	}

	.qt .el-input.el-input--suffix {
		width: 160px !important;
	}

	.el-form-item.wl {
		margin: 0px 25px 13px 0px !important;
	}

	.fx input.el-input__inner {
		font-size: 16px;
		border: none !important;
	}
}
</style>
<style rel="stylesheet/scss" lang="scss">
#supplement {
	::-webkit-input-placeholder {
		font-weight: bold;
		color: #7c7c7c;
	}

	:-moz-placeholder {
		font-weight: bold;
		color: #7c7c7c;
	}

	::-moz-placeholder {
		font-weight: bold;
		color: #7c7c7c;
	}

	:-ms-input-placeholder {
		font-weight: bold;
		color: #7c7c7c;
	}

	.el-input {
		width: 200px;
	}

	.el-input__suffix-inner {
		font-size: 16px;
	}

	.el-input--suffix .el-input__inner {
		font-size: 16px;
	}

	.el-input__inner {
		border-radius: 5px;
		border: 2px solid #4e6ffc;
	}

	.el-textarea__inner {
		border-radius: 5px;
		border: 2px solid #4e6ffc;
		resize: none;
		height: 150px !important;
		display: inherit;
		font-size: 18px;
		font-family: none;
	}

	.el-form {
		padding: 30px 50px;
		margin-bottom: 35px;
		background: #fff;
		display: flex;
		flex-wrap: wrap;
	}

	.fx {
		display: block !important;
		text-align: left;
		padding: 30px 0;

		.el-form-item {
			border-bottom: 1px solid #ddd;

			.el-form-item__content {
				display: flex;
				align-items: flex-start;

				.el-input {
					width: 80%;
				}
			}
		}

		.el-textarea__inner {
			border: none !important;
			font-size: 16px;
			font-weight: bold;
			color: #000;
		}

	}

	.el-textarea {
		width: 85%;
	}

	.el-checkbox__label {
		line-height: 37px;
		font-size: 16px;
	}

	.el-button {
		background: #9eacfd;
	}
}
</style>
